import type {
  LineItem,
  LineItemCreate,
  Order,
  Shipment,
  StockItem,
} from '@commercelayer/sdk';
import type { ListResponse } from '@commercelayer/sdk/lib/cjs/resource';
import axios from 'axios';

import type { AttributionParams } from 'checkout/types/checkout';
import type { Channel } from 'shared/constants/Channel';
import { client } from 'shared/infra/commerceLayer/client';
import type { TrackingParams } from 'shared/services/attribution';
import type { LineItemType } from 'shared/types/product';

export interface OrderMetadata extends AttributionParams, TrackingParams {
  business_account_offer_available?: true;
  sales_channel: Channel;
  client_id: 'storefront-web';
}

// TODO: Remove global type upstream
const addressTypeMap = {
  shippingAddress: 'shipping_address',
  billingAddress: 'billing_address',
};

export const getOrder = async (orderId: string): Promise<Order | null> => {
  const clClient = await client.get();

  try {
    return await clClient.orders.retrieve(orderId, {
      include: [
        'line_items',
        'shipping_address',
        'billing_address',
        'shipments',
        'payment_method',
      ],
    });
  } catch (err) {
    // The CL Sales Channel token used by the Store does not have permission
    // to fetch orders with 'approved' status. Unfortunately the CL SDK seems
    // to not provide a proper error type (e.g. SDKError or APIError) thus neither
    // an error.code to identify this case, so we fall back to checking the error
    // message for this case.
    const isOrderApproved =
      err instanceof TypeError &&
      err.message.includes(
        "Cannot read properties of undefined (reading 'links')",
      );

    if (isOrderApproved) {
      return null;
    }

    throw err;
  }
};

export const createOrder = async (
  channel: Channel = 'signup',
  couponCode?: string,
  languageCode?: string,
  attributionParams?: AttributionParams,
  trackingParams?: TrackingParams,
  isBusinessAccountBundleEnabled?: boolean,
): Promise<Order> => {
  /**
   * We need to wait for the client initialization here to ensure that the
   * authentication is automatically refreshed once the token expires.
   */
  const token = await client.getAccessToken();

  const metadata: OrderMetadata = {
    ...attributionParams,
    ...trackingParams,
    sales_channel: channel,
    client_id: 'storefront-web',
  };

  if (isBusinessAccountBundleEnabled) {
    metadata.business_account_offer_available = true;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (
    axios
      .post<Order>(
        '/api/order/create',
        { couponCode, languageCode, metadata },
        {
          headers: {
            'x-commercelayer-authorization': `Bearer ${token.accessToken}`,
          },
        },
      )
      .then((response) => response.data)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      .catch((error) => error)
  );
};

export const addProduct = async (
  orderId: string,
  skuCode: string,
  lineItemType: LineItemType,
  quantity = 1,
  localizedName?: string,
): Promise<LineItem> => {
  const clClient = await client.get();
  const payload: LineItemCreate = {
    quantity,
    metadata: {
      localized_name: localizedName,
    },
    order: clClient.orders.relationship(orderId),
  };

  if (lineItemType === 'sku') {
    payload.sku_code = skuCode;
  }

  if (lineItemType === 'bundle') {
    payload.bundle_code = skuCode;
  }

  return clClient.line_items.create(payload);
};

export const removeProduct = async (id: string): Promise<void> => {
  const clClient = await client.get();

  return clClient.line_items.delete(id);
};

export const updateOrder = async (
  orderId: string,
  updateDetails: Record<string, unknown>,
): Promise<Order> => {
  const clClient = await client.get();

  return clClient.orders.update({ id: orderId, ...updateDetails });
};

export const addPaymentMethod = async (
  orderId: string,
  paymentMethodId: string,
): Promise<Order> => {
  const clClient = await client.get();

  return clClient.orders.update({
    id: orderId,
    payment_method: clClient.payment_methods.relationship(paymentMethodId),
  });
};

export const addShippingMethod = async (
  shipmentID: string,
  shippingMethodID: string,
): Promise<Shipment> => {
  const clClient = await client.get();

  return clClient.shipments.update({
    id: shipmentID,
    shipping_method: clClient.shipping_methods.relationship(shippingMethodID),
  });
};

export const addAddressToOrder = async (
  orderID: string,
  addressId: string,
  addressType: string,
): Promise<Order> => {
  const clClient = await client.get();

  return clClient.orders.update({
    id: orderID,
    [addressTypeMap[addressType]]: clClient.addresses.relationship(addressId),
    _update_taxes: true,
  });
};

export const updateLineItem = async (
  lineItemId: string,
  updateDetails: Record<string, unknown>,
): Promise<LineItem> => {
  const clClient = await client.get();

  return clClient.line_items.update({
    id: lineItemId,
    ...updateDetails,
  });
};

export const removeLineItem = async (lineItemId: string): Promise<void> => {
  const clClient = await client.get();

  return clClient.line_items.delete(lineItemId);
};

export const updateTaxes = async (orderID: string): Promise<void> => {
  const clClient = await client.get();

  await clClient.orders.update({
    id: orderID,
    _update_taxes: true,
  });
};

interface PaymentInformation {
  purpose: string;
  number: string | null;
}

export const getPaymentInformation = async (
  orderId: string,
): Promise<PaymentInformation | null> => {
  const clClient = await client.get();

  const order = await clClient.orders.retrieve(orderId, {
    include: ['payment_source'],
  });

  if (!order.payment_source?.metadata) {
    const errorMessage = `No Payment Source metadata found for order ${orderId}, bankwire details will not be displayed`;
    throw new Error(errorMessage);
  } else {
    const purpose = order.payment_source.metadata?.bankwire_purpose as string;
    if (typeof purpose === 'string') {
      return {
        purpose,
        number: order.number,
      };
    }

    return null;
  }
};

export const addCoupon = async (
  orderID: string,
  code: string,
): Promise<Order> => {
  const clClient = await client.get();

  // TODO: add includes
  return clClient.orders.update({
    id: orderID,
    coupon_code: code,
    _update_taxes: true,
  });
};

export const removeCoupon = async (
  orderId: string,
): Promise<ReturnType<typeof updateOrder> | void> =>
  updateOrder(orderId, { coupon_code: null });

export const getStockItems = async (
  skus: string[],
): Promise<ListResponse<StockItem>> => {
  const clClient = await client.get();

  return clClient.stock_items.list({
    filters: { sku_code_in: skus.join(',') },
  });
};
