import { formatCurrencyToParts, resolveCurrencyFormat } from '@sumup-oss/intl';

import type { Maybe } from 'types/util';

// 'currencyLocale' (resolveCurrencyFormat) returned for these locales
// is not the same as the country locale pair provided for localization
// for currency formatting purposes we need to adjust them
// HUF 21 -> 21 Ft (keep native formatting)
const KEEP_NATIVE_LOCALE_CURRENCY = {
  'en-AT': 'de-AT',
  'en-BG': 'bg-BG',
  'en-CY': 'el-CY',
  'en-HR': 'hr-HR',
  'en-CZ': 'cs-CZ',
  'en-EE': 'et-EE',
  'en-FI': 'fi-FI',
  'en-GR': 'el-GR',
  'en-HU': 'hu-HU',
  'en-LV': 'lv-LT',
  'en-LT': 'lt-LT',
  // to keep the display consistent with CL use sv-SE for NO
  'nb-NO': 'sv-SE',
  'en-NO': 'sv-SE',
  'en-PT': 'pt-PT',
  'en-RO': 'ro-RO',
  'en-SK': 'sk-SK',
  'en-SI': 'sl-SI',
  'en-ES': 'es-ES',
  'en-LU': 'es-ES',
};

// for specific en locales the 'currencyLocale' is the same as the input localization locale
// but the display currency that we want for these values is not the same as the native currency
// so we are adjusting those to match
// € 12 -> 12 € (keep native formatting)
const KEEP_NATIVE_LOCALE_CURRENCY_DEFAULTS = [
  'en-AT',
  'en-CY',
  'en-FI',
  'en-SI',
  'nb-NO',
];

// for these currencies the currency codes are returned from Intl.NumberFormat
// so we are changing those from this collection
const ALTERNATIVE_SYMBOLS = {
  HRK: 'kn',
  NOK: 'kr',
  RON: 'Lei',
};

const DEFAULT_CURRENCY_LOCALE = 'en-US';

export const formatCurrencyWithLocale = (
  amount: Maybe<number> | undefined,
  locale = DEFAULT_CURRENCY_LOCALE,
): string => {
  if (typeof amount !== 'number') {
    return '';
  }

  const currencyFormat = resolveCurrencyFormat(locale);
  const currencyLocale = currencyFormat?.locale || locale;

  // we are adjusting the locale for display purposes
  let adjustedLocale = locale;
  if (
    currencyLocale !== locale ||
    KEEP_NATIVE_LOCALE_CURRENCY_DEFAULTS.includes(locale)
  ) {
    adjustedLocale = (KEEP_NATIVE_LOCALE_CURRENCY[locale] as string) || locale;
  }
  let parts = formatCurrencyToParts(amount, adjustedLocale);
  const fractionPart = parts.find(({ type }) => type === 'fraction');

  const localeIsHU = locale.endsWith('-HU');

  // Implementing rounding for *-HU since fraction won't be shown
  if (localeIsHU) {
    parts = formatCurrencyToParts(Math.round(amount), adjustedLocale);
  }

  const isEmptyFraction = fractionPart && !Number(fractionPart.value);

  const output = parts
    .map(({ type, value }) => {
      // if the fraction is empty skip it!
      const defaultCaseOmitFraction =
        isEmptyFraction && (type === 'fraction' || type === 'decimal');
      // do not display fraction for *-HU locale at all
      const caseHU =
        !isEmptyFraction &&
        localeIsHU &&
        (type === 'fraction' || type === 'decimal');

      // return the currency string without fractions
      if (defaultCaseOmitFraction || caseHU) {
        return '';
      }

      // https://bugs.chromium.org/p/v8/issues/detail?id=8602
      if (type === 'currency' && ALTERNATIVE_SYMBOLS[value]) {
        return ALTERNATIVE_SYMBOLS[value] as string;
      }

      return value;
    })
    .join('');

  return output;
};
