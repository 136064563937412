import { action } from 'typesafe-actions';

import {
  PaymentActionTypes,
  type AttachPaymentMethod,
  type AttachPaymentMethodFailure,
  type AttachPaymentMethodSuccess,
  type PaymentState,
  type ResetPayment,
  type SetPaymentErrorAction,
  type SetPaymentLoadingAction,
} from './types';

export const setPaymentLoadingAction = (
  isLoading?: boolean,
): SetPaymentLoadingAction =>
  action(PaymentActionTypes.SET_LOADING, { isLoading });

export const setPaymentErrorAction = (error: string): SetPaymentErrorAction =>
  action(PaymentActionTypes.SET_ERROR, { error });

export const attachPaymentMethodAction = (): AttachPaymentMethod =>
  action(PaymentActionTypes.ATTACH_PAYMENT_METHOD_REQUEST);

export const attachPaymentMethodSuccessAction = (
  payload: Pick<PaymentState, 'id' | 'reference'>,
): AttachPaymentMethodSuccess =>
  action(PaymentActionTypes.ATTACH_PAYMENT_METHOD_SUCCESS, payload);

export const attachPaymentMethodFailureAction = (
  error: AttachPaymentMethodFailure['payload']['error'],
): AttachPaymentMethodFailure =>
  action(PaymentActionTypes.ATTACH_PAYMENT_METHOD_FAILURE, { error });

export const resetPayment = (): ResetPayment =>
  action(PaymentActionTypes.RESET_PAYMENT);
